
/**
 * From Manfred Steyer
 * https://www.angulararchitects.io/en/blog/smarter-not-harder-simplifying-your-application-with-ngrx-signal-store-and-custom-features/
 */

import { Signal, computed } from '@angular/core';
import {
	EmptyFeatureResult,
	SignalStoreFeature,
	patchState,
	signalStoreFeature,
	withComputed,
	withState,
} from '@ngrx/signals';
import { Observable, firstValueFrom, from } from 'rxjs';
import { saveAs } from 'file-saver';

import { ExportResponse } from '../../models';

import { CallState, NamedCallStateSignals, NamedCallStateSlice } from './call-state.feature';

export type NamedExportCallStateSlice<Collection extends string> = NamedCallStateSlice<`${Collection}Export`>;

export type ExportCallStateSlice = {
	exportCallState: CallState;
};

export type NamedExportCallStateSignals<Prop extends string> = NamedCallStateSignals<`${Prop}Export`>;

export type ExportCallStateSignals = {
	exportLoading: Signal<boolean>;
	exportLoaded: Signal<boolean>;
	exportError: Signal<string | null>;
};

export function getExportCallStateKeys(config?: { collection?: string }) {
	const prop = config?.collection;
	return {
		callStateKey: prop ? `${config.collection}ExportCallState` : 'exportCallState',
		loadingKey: prop ? `${config.collection}ExportLoading` : 'exportLoading',
		loadedKey: prop ? `${config.collection}ExportLoaded` : 'exportLoaded',
		errorKey: prop ? `${config.collection}ExportError` : 'exportError',
	};
}

export function withExportCallState<Collection extends string>(config: {
	collection: Collection;
}): SignalStoreFeature<
	EmptyFeatureResult,
	{
		state: NamedExportCallStateSlice<Collection>;
		props: NamedExportCallStateSignals<Collection>;
		methods: {};
	}
>;
export function withExportCallState(): SignalStoreFeature<
	EmptyFeatureResult,
	{
		state: ExportCallStateSlice;
		props: ExportCallStateSignals;
		methods: {};
	}
>;
export function withExportCallState<Collection extends string>(config?: {
	collection: Collection;
}): SignalStoreFeature {
	const { callStateKey, errorKey, loadedKey, loadingKey } =
		getExportCallStateKeys(config);

	return signalStoreFeature(
		withState({ [callStateKey]: 'init' }),
		withComputed((state: Record<string, Signal<unknown>>) => {
			const callState = state[callStateKey] as Signal<CallState>;

			return {
				[loadingKey]: computed(() => callState() === 'loading'),
				[loadedKey]: computed(() => callState() === 'loaded'),
				[errorKey]: computed(() => {
					const v = callState();
					return typeof v === 'object' ? v.error : null;
				}),
			};
		}),
	);
}

export function setExportLoading<Prop extends string>(
	prop?: Prop,
) {
	return state => {
		if (prop) {
			return { ...state, [`${prop}ExportCallState`]: 'loading' } as NamedExportCallStateSlice<Prop>;
		}

		return { ...state, exportCallState: 'loading' };
	}
}

export function setExportLoaded<Prop extends string>(
	prop?: Prop,
) {
	return state => {
		if (prop) {
			return { ...state, [`${prop}ExportCallState`]: 'loaded' } as NamedExportCallStateSlice<Prop>;
		} else {
			return { ...state, exportCallState: 'loaded' };
		}
	}
}

export function setExportError<Prop extends string>(
	error: string,
	prop?: Prop,
) {
	return state => {
		if (prop) {
			return { ...state, [`${prop}ExportCallState`]: { error } } as NamedExportCallStateSlice<Prop>;
		} else {
			return { ...state, exportCallState: { error } };
		}
	}
}

export async function exportStated<E extends ExportResponse>(p: Observable<E>, store, prop?: string) {
	patchState(store, setExportLoading(prop))
	return await firstValueFrom(from(p))
		.then(res => {
			patchState(store, setExportLoaded(prop))
			saveAs(res.blob, res.fileName);
			return res;
		})
		.catch(e => {
			patchState(store, setExportError(e, prop))
			throw e;
		})
}