import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Component, computed, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SafePipe } from '@aston/foundation';

import { ContextHelpIframeService } from '../context-help-iframe.service';

@Component({
    styles: `
        iframe {
            border: none;
            height: 100vh;
            width: 100%;
        }
    `,
    template: `
        @if (url(); as src) {
            <iframe #iframe (error)="onIframeError(iframe, src, $event)" [src]="src | safe:'resourceUrl'"></iframe>
        }
    `,
    imports: [CommonModule, SafePipe, TranslateModule]
})
export class ContextHelpPageComponent {
    ctxHelpService = inject(ContextHelpIframeService)
    route = inject(ActivatedRoute)
    sanitizer = inject(DomSanitizer)

    url = computed(() => this.ctxHelpService.pageUrl(this.route.snapshot.data['token']))

	onIframeError(_iframe: HTMLIFrameElement, _src: SafeUrl, _e: Event) {
		console.warn('[context-help] failed to load page.')
	}
}
