import { signalStoreFeature, type, withMethods, withState } from '@ngrx/signals';
import { ContextHelpCarouselService } from '@aston/context-help';
import { UserPrefsStoreActions } from '@aston/user-prefs';
import { TranslateService } from '@ngx-translate/core';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import * as AppStoreSelectors from '../../../root-store/app-store/selectors';
import { AppConstants } from '../../../app.constants';

export function withQuickstart<_>() {
	return signalStoreFeature(
		{ state: type<{ homeLoadedOnce: boolean }>() },

		withState({
			skipQuickstart: false,
		}),

		withMethods(store => {
			const fatStore = inject(Store)
			const i18n = inject(TranslateService)
			const help = inject(ContextHelpCarouselService)

			const showQuickstart = async () => {
				const prefs = await firstValueFrom(fatStore.select(AppStoreSelectors.selectCurrentUserPrefs))
				const pages = AppConstants.QUICKSTART_PAGES
				const show = window['__aston_debug_start'] || (true
					&& !store.homeLoadedOnce()
					&& !!pages?.length
					&& !prefs?.skipQuickstart)

				if (!show) return;

				const component = help.show(Object.values(i18n.instant(pages)), i18n.instant('KB.QuickStartTitle'))
				if (!component) {
					console.warn('could not show the quickstart carousel')
					return;
				}

				component.buttonLabel = i18n.instant('KB.QuickStartHideForever')
				component.ngOnChanges()
				component.buttonClick.subscribe(_ => {
					fatStore.dispatch(UserPrefsStoreActions.UpdateUISettingRequest({ skipQuickstart: true }))
					component.activeModal.close()
				});
			}

			return {
				showQuickstart,
			}
		})
	)
}