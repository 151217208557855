import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { ContextHelpIframeService } from '../context-help-iframe.service';

@Component({
	selector: 'context-help-carousel',
	templateUrl: './context-help-carousel.component.html',
	styles: [`iframe { border: none; }`],
	standalone: false
})
export class ContextHelpCarouselComponent implements OnChanges {
	@ViewChild('ngb-carousel', { static: true }) carousel?: NgbCarousel;
	@Input() timeout = 2000;
	@Input() title = '';
	@Input() urls: string[] = [];
	@Input() interval = 0;
	@Input() buttonLabel = '';
	@Output() buttonClick = new EventEmitter();

	_urls: SafeUrl[] = [];
	firstPageLoaded: boolean | null = null;
	firstPageTimeout = 0;

	constructor(
		private ctxHelpService: ContextHelpIframeService,
		public activeModal: NgbActiveModal,
		public domSanitizer: DomSanitizer,
	) {
		// listen to this specific event
		// the Bookstack app will dispatch on any page load
		this.ctxHelpService.helpPageLoaded$.subscribe(event => this.onIframeLoad(event))
	}

	ngOnChanges() {
		this._urls = this.urls.map(u => this.domSanitizer.bypassSecurityTrustResourceUrl(u))

		if (this.firstPageTimeout) clearTimeout(this.firstPageTimeout)
		if (!this.firstPageLoaded) this.firstPageTimeout = setTimeout(_ => {
			// first error: try a reload
			if (this.firstPageLoaded === null) {
				console.warn('[context-help] after some delay, the first page is still not loaded, let give it 1 more chance.')
				this.firstPageLoaded = false
				this._urls = [...this._urls]

			// second error: abort
			} else if (this.firstPageLoaded === false) {
				console.warn('[context-help] after a first retry, the first page is still not loaded, give up.')
				this.activeModal.dismiss()
			}
		}, this.timeout)
	}

	onIframeLoad(_: MessageEvent) {
		this.firstPageLoaded = true;
	}

	onIframeError(_iframe: HTMLIFrameElement, _src: SafeUrl, _e: Event) {
		console.warn('[context-help] failed to load page.')
	}
}
