import { patchState, signalStoreFeature, type, withMethods } from '@ngrx/signals';
import { IFilter } from '@aston/foundation';
import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { SuperCustomersListService } from '../../../customers-module/services';

export function withInitializer<_>() {
	return signalStoreFeature(
		{
			state: type<{
				filters: IFilter[],
				isEmpty: boolean,
				isDirty: boolean,
				homeLoadedOnce: boolean,
				shouldBeReloaded: boolean,
			}>(),
			methods: type<{
				showQuickstart: () => void;
			}>()
		},

		withMethods(store => {
			const superCustomers = inject(SuperCustomersListService)

			const init = async () => {
				patchState(store, {
					// check if some SC are present only once
					isEmpty: store.isEmpty() === undefined
						? await firstValueFrom(superCustomers.noSuperCustomersPresent())
						: store.isEmpty()
				})
				
				// just some useful mappings
				const first = !store.homeLoadedOnce()

				// first display on each page (or a forced reload)
				// should call each chart api endpoints
				if (first || store.shouldBeReloaded()) {
					store.showQuickstart()
	
					patchState(store, {
						shouldBeReloaded: false,
						homeLoadedOnce: true,
						isDirty: true,
					})
				}
			}

			return {
				init,

				addFilters: (filters: IFilter[]) => {
					patchState(store, {
						filters: [...store.filters(), ...filters ]
					})
				},
	
				removeFilters: (filters: IFilter[]) => {
					patchState(store, {
						filters: store.filters().filter(f => !filters.includes(f))
					})
				}	
			};
		}),
	)
}