import { ExportResponse, NamedExportCallStateSignals, NamedExportCallStateSlice, NotificationService, exportStated, withExportCallState } from '@aston/foundation';
import { EmptyFeatureResult, SignalStoreFeature, signalStoreFeature, withMethods } from '@ngrx/signals';
import { Observable, catchError, throwError } from 'rxjs';
import { inject } from '@angular/core';

import { HomeExportsService } from '../../services/home.exports.service';

export type NamedChartsExportMethods<Prop extends string, A1 = void, A2 = void, CProp extends string = Capitalize<Prop>> = {
	[K in CProp as `export${K}`]: (a1: A1, a2: A2) => Promise<ExportResponse>;
}

export function withGenericExport<Collection extends string, A1 = void, A2 = void>({
	collection,
}: { collection: Collection }): SignalStoreFeature<
EmptyFeatureResult,
{
	state: NamedExportCallStateSlice<Collection>;
	props: NamedExportCallStateSignals<Collection>;
	methods: NamedChartsExportMethods<Collection, A1, A2>;
}
> {
	const Name = collection[0].toUpperCase() + collection.slice(1)
	return signalStoreFeature(
		{},
		withExportCallState({ collection }),
		withMethods((store, exports = inject(HomeExportsService), notify = inject(NotificationService)) => {
			const exportUserChart = exports[`export${Name}Kpis`] as (...args: any) => Observable<ExportResponse>

			if (!exportUserChart) {
				console.error(`[charts] "${collection}" is not a valid slice name, there is no matching HomeExportsService::export${Name}Kpis method.`)
			}

			return {
				[`export${Name}`]: (a1: A1, a2: A2) => exportStated(
					exportUserChart.bind(exports)(a1, a2).pipe(
						catchError(error => {
							if (error.is('AlreadyRunning')) notify.errorMessage('Errors.OperationRequestAlreadyRunning')
							return throwError(() => error)
						})
					),
					store, collection),
			} as NamedChartsExportMethods<Collection, A1, A2>
		}),
	// FIXME: cannot make this typed properly :(
	) as SignalStoreFeature<
		EmptyFeatureResult,
		{
			state: NamedExportCallStateSlice<Collection>;
			props: NamedExportCallStateSignals<Collection>;
			methods: NamedChartsExportMethods<Collection, A1, A2>;
		}
		>
}