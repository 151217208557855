
/**
 * From Manfred Steyer
 * https://www.angulararchitects.io/en/blog/smarter-not-harder-simplifying-your-application-with-ngrx-signal-store-and-custom-features/
 */

import { Signal, computed } from '@angular/core';
import {
	EmptyFeatureResult,
	SignalStoreFeature,
	SignalStoreFeatureResult,
	patchState,
	signalStoreFeature,
	withComputed,
	withMethods,
	withState,
} from '@ngrx/signals';
import { Observable, firstValueFrom, from } from 'rxjs';

import { Loader, SignalLoader } from '../../classes';

export type CallState = 'init' | 'loading' | 'loaded' | { error: string };

export type NamedCallStateSlice<Collection extends string> = {
	[K in Collection as `${K}CallState`]: CallState;
};

export type CallStateSlice = {
	callState: CallState;
};

export type NamedCallStateSignals<Prop extends string> = {
	[K in Prop as `${K}Loading`]: Signal<boolean>;
} & {
	[K in Prop as `${K}Loaded`]: Signal<boolean>;
} & {
	[K in Prop as `${K}Error`]: Signal<string | null>;
};

export type NamedCallStateMethods<Prop extends string> = {
	[K in Prop as `${K}Loader`]: () => Loader;
}

export type CallStateSignals = {
	loading: Signal<boolean>;
	loaded: Signal<boolean>;
	error: Signal<string | null>;
};

export type CallStateMethods = {
	loader: () => Loader;
};

export function getCallStateKeys(config?: { collection?: string }) {
	const prop = config?.collection;
	return {
		callStateKey: prop ? `${config.collection}CallState` : 'callState',
		entityKey: prop ? `${config.collection}` : 'entity',
		loaderKey: prop ? `${config.collection}Loader` : 'loader',
		loadingKey: prop ? `${config.collection}Loading` : 'loading',
		loadedKey: prop ? `${config.collection}Loaded` : 'loaded',
		errorKey: prop ? `${config.collection}Error` : 'error',
	};
}

export function withCallState<Collection extends string>(config: {
	collection: Collection;
}): SignalStoreFeature<
	EmptyFeatureResult,
	{
		state: NamedCallStateSlice<Collection>;
		props: NamedCallStateSignals<Collection>;
		methods: NamedCallStateMethods<Collection>;
	}
>;
export function withCallState(): SignalStoreFeature<
	EmptyFeatureResult,
	{
		state: CallStateSlice;
		props: CallStateSignals;
		methods: CallStateMethods;
	}
>;
export function withCallState<Collection extends string>(config?: {
	collection: Collection;
}): SignalStoreFeature {
	const { callStateKey, errorKey, loadedKey, loaderKey, loadingKey, entityKey } =
		getCallStateKeys(config);

	const initialState = { [callStateKey]: 'init' };
	if (!config?.collection) initialState[entityKey] = null;

	return signalStoreFeature(
		withState(initialState),
		withComputed((state: Record<string, Signal<unknown>>) => {
			const callState = state[callStateKey] as Signal<CallState>;

			return {
				[loadingKey]: computed(() => callState() === 'loading'),
				[loadedKey]: computed(() => callState() === 'loaded'),
				[errorKey]: computed(() => {
					const v = callState();
					return typeof v === 'object' ? v.error : null;
				}),
			};
		}),
		withMethods((state: Record<string, Signal<unknown>>) => {
			const loader = new SignalLoader(
				state[loadingKey] as Signal<boolean>,
				state[errorKey] as Signal<string>);

			return  {
				[loaderKey]: () =>loader,
			}
		})
	);
}

export function setLoading<Prop extends string>(
	prop?: Prop,
) {
	return (state) => {
		if (prop) {
			return { ...state, [`${prop}CallState`]: 'loading' } as NamedCallStateSlice<Prop>;
		} else {
			return { ...state, callState: 'loading' };
		}
	}
}

export function setLoaded<Prop extends string>(
	prop?: Prop,
) {
	return (state) => {
		if (prop) {
			return { ...state, [`${prop}CallState`]: 'loaded' } as NamedCallStateSlice<Prop>;
		} else {
			return { ...state, callState: 'loaded' };
		}
	}
}

export function setError<Prop extends string>(
	rawError: Error,
	prop?: Prop,
) {
	const error = rawError?.toString();
	
	return (state) => {
		if (prop) {
			return { ...state, [`${prop}CallState`]: { error } } as NamedCallStateSlice<Prop>;
		} else {
			return { ...state, callState: { error } };
		}
	}
}

export type CallStatedOptions = {
	setLoading?: boolean;
	stateOnly?: boolean;
}

export async function callStated<E>(p: Observable<E>, store, prop?: string, options: CallStatedOptions = {}) {
	if (!options || (options.setLoading ?? true)) {
		patchState(store, setLoading(prop))
	}

	return await firstValueFrom(from(p))
		.then(entity => {
			patchState(store, setLoaded(prop))
			if (!options.stateOnly) patchState(store, { [prop || 'entity']: entity })
			return entity;
		})
		.catch(e => {
			patchState(store, setError(e, prop))
			throw e;
		})
}
