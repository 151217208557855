import { ICountryMarker } from '@aston/charts/models';
import { signalStoreFeature } from '@ngrx/signals';

import { DsoModel, IHomeTurnoverChartData, IHomeTopKpis, IHomeScoringSlices, ITopDebtorsWithClaims, TopOutstandingList } from '../../models';
import { OutstandingMode } from '../../../shared-module/enums';
import { ISuperCustomerAgeingBalanceChartData, ISuperCustomerAverageOverdueChartData, ISuperCustomerEstimatedCashingChartData, ISuperCustomerOutstandingByDunningLevelChartData, ISuperCustomerRealCashingChartData, SuperCustomerEstimatedCashingMode } from '../../../customers-module/models';

import { withGenericChart } from './home-dashboard.generic-chart';
import { withGenericExport } from './home-dashboard.generic-export';

const TOP_OUTSTANDING_CLIENTS = 'topOutstandingClients'
const AGEING_BALANCE_DETAILED = 'detailedAgeingBalance'
const TOP_DEBTORS_WITH_CLAIMS = 'topDebtorsWithClaims'
const OUTSTANDING = 'outstandingAmountByDunningLevels'
const SUPERCUSTOMER_SCORINGS = 'superCustomerScorings'
const TURNOVER_CHARTDATA = 'turnoverChartData'
const TURNOVER_WORLDMAP = 'turnoverWorldMap'
const ESTIMATED_CASHING = 'estimatedCashing'
const AVERAGE_OVERDUE = 'averageOverdue'
const DSO_MONITORING = 'dsoMonitoring'
const AGEING_BALANCE = 'ageingBalance'
const REAL_CASHING = 'realCashing'
const TOP_KPIS = 'topKpis'

export function withHomeCharts<_>() {
	return signalStoreFeature(
		{},

		withGenericExport<typeof AGEING_BALANCE>({ collection: AGEING_BALANCE }),
		withGenericExport<typeof AGEING_BALANCE_DETAILED>({ collection: AGEING_BALANCE_DETAILED }),
	
		withSpecificCharts(),	
		withGenericCharts(),
		withChartAndExport<ISuperCustomerEstimatedCashingChartData, typeof ESTIMATED_CASHING, SuperCustomerEstimatedCashingMode>(ESTIMATED_CASHING),
	)
}

function withChartAndExport<Data, Collection extends string, A1 = void, A2 = void>(collection: Collection) {
	return signalStoreFeature(
		{},
		withGenericChart<Data, Collection, A1, A2>({ collection }),
		withGenericExport<Collection, A1, A2>({ collection }),
	)
}

function withSpecificCharts<_>() {
	return signalStoreFeature(
		{},
		withChartAndExport<DsoModel[], typeof DSO_MONITORING>(DSO_MONITORING),
		withChartAndExport<ICountryMarker[], typeof TURNOVER_WORLDMAP>(TURNOVER_WORLDMAP),
		withChartAndExport<IHomeTurnoverChartData, typeof TURNOVER_CHARTDATA>(TURNOVER_CHARTDATA),
		withChartAndExport<ISuperCustomerAverageOverdueChartData, typeof AVERAGE_OVERDUE>(AVERAGE_OVERDUE),
		withChartAndExport<ISuperCustomerRealCashingChartData, typeof REAL_CASHING>(REAL_CASHING),
		withChartAndExport<ITopDebtorsWithClaims, typeof TOP_DEBTORS_WITH_CLAIMS>(TOP_DEBTORS_WITH_CLAIMS),
		withChartAndExport<ISuperCustomerOutstandingByDunningLevelChartData, typeof OUTSTANDING>(OUTSTANDING),
	)
}

function withGenericCharts<_>() {
	return signalStoreFeature(
		{},
		withGenericChart<IHomeTopKpis, typeof TOP_KPIS>({ collection: TOP_KPIS }),
		withGenericChart<IHomeScoringSlices, typeof SUPERCUSTOMER_SCORINGS>({ collection: SUPERCUSTOMER_SCORINGS }),
		withGenericChart<ISuperCustomerAgeingBalanceChartData, typeof AGEING_BALANCE>({ collection: AGEING_BALANCE }),
		withGenericChart<TopOutstandingList, typeof TOP_OUTSTANDING_CLIENTS, OutstandingMode>({ collection: TOP_OUTSTANDING_CLIENTS }),
	)
}
