import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { toObservable } from '@angular/core/rxjs-interop';
import { computed, effect } from '@angular/core';
import { IFilter } from '@aston/foundation';

import { IUserItem } from '../../../user-module/models';

import { withInitializer } from './home-dashboard.initializer';
import { withHomeChartsNavigation } from './home-dashboard.navigation';
import { withQuickstart } from './home-dashboard.quickstart';
import { withHomeCharts } from './home-dashboard.charts';

export const HomeDashboardStore = signalStore(
	{ providedIn: 'root', protectedState: false },

	withState({
		error: null,
		isDirty: false,
		isLoading: false,
		isEmpty: undefined,
		homeLoadedOnce: false,
		shouldBeReloaded: false,
		filters: [] as IFilter[],
		users: [] as IUserItem[],
	}),

	withQuickstart(),

	withHomeCharts(),
	withHomeChartsNavigation(),

	withMethods(store => {
		const isDirtyObservable = toObservable(store.isDirty)
		return {
			isDirtyObservable: () => isDirtyObservable,
			markToBeReloaded: () => patchState(store, { shouldBeReloaded: true }),
			markAsDirty: (isDirty = true) => {
				patchState(store, { isDirty: !isDirty })
				setTimeout(_ => patchState(store, { isDirty }), 100)
			},

			noDataToShow: computed(() => true
				&& store.outstandingAmountByDunningLevels()?.every(c => c.outstandingAmountDunningLevels.every(l => l.totalOutstandingAmount === 0))
				&& store.ageingBalance()?.every(c => c.accountingDocumentCount === 0))
		}
	}),

	withInitializer(),

	withHooks({
		onInit(store) {
			effect(() => {
				// when
				store.filters()
				// then
				if (store.homeLoadedOnce()) store.markAsDirty()
			})
		}
	})
);

export type HomeDashboardStore = InstanceType<typeof HomeDashboardStore>;
