import { inject, Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { tap, switchMap, map, withLatestFrom, debounceTime } from 'rxjs/operators';
import { SuperCustomersListService } from 'apps/federation/src/app/customers-module/services';
import { IFilter, newAggregationId } from '@aston/foundation';
import { saveAs } from 'file-saver';

import { AccountingDocumentsService, AccountingListFilterSpecService } from '../../accounting-module/services';
import { AppStoreActions, AppStoreSelectors, catchWithAppError as catchError } from '../app-store';
import { aggregate, CorrelationParams } from '../models/correlated-actions.model';
import { HomeDashboardStore } from '../../home-module/stores';

import { ISuperCustomerPageState } from './state';
import * as featureSelectors from './selectors';
import * as featureActions from './actions';

@Injectable({providedIn: 'root'})
export class SuperCustomerPageStoreEffects {
	private actions$ = inject(Actions);
	private fatStore = inject(Store);
	private store = inject(HomeDashboardStore);
	private superCustomersService = inject(SuperCustomersListService);
	private accountingService = inject(AccountingDocumentsService);
	private accountingListFilterSpecService = inject(AccountingListFilterSpecService);

	onDebug$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.Debug),
		tap(action => console.log(`Debug action %o triggered by`, action.message, action.origin))
	), {dispatch: false});

	initSuperDebtorEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitSuperCustomerPageRequest),
		switchMap(action => {
			const correlationParams: CorrelationParams = action.correlationParams;
			correlationParams.parentActionType = action.type;

			return [
				featureActions.LoadSuperCustomerIdentityRequest(action.id, correlationParams),
				featureActions.LoadAttachedDebtorsRequest(correlationParams),
			];
		}),
		catchError(error => of(featureActions.InitSuperCustomerPageFailure({error})))
	));

	initSuperDebtorSuccessEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitSuperCustomerPageRequest),
		// Create an aggregated action listener
		// it will observe the race of all specified observable
		// and complete when all these actions are dispatched.
		// Note that individual actions workflow still apply.
		aggregate(
			this.actions$.pipe(ofType(featureActions.LoadSuperCustomerIdentitySuccess)),
			this.actions$.pipe(ofType(featureActions.LoadAttachedDebtorsSuccess)),
			this.actions$.pipe(ofType(featureActions.InitSuperCustomerPageFailure))
		),
		switchMap(_ => [
			featureActions.InitSuperCustomerPageSuccess(),
			featureActions.LoadAllAttachedDebtorsRequest(),
		]),
		catchError(error => of(featureActions.InitSuperCustomerPageFailure({error})))
	));

	loadSuperDebtorIdentityRequestEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperCustomerIdentityRequest),
		withLatestFrom(this.fatStore),
		switchMap(([action]) => this.superCustomersService.getSuperCustomerIdentity(action.id).pipe(
			switchMap(superDebtorIdentity => [
				featureActions.LoadSuperCustomerIdentitySuccess({
					entity: superDebtorIdentity,
					correlationParams: action.correlationParams
				}),
			]),
			catchError(error => of(featureActions.InitSuperCustomerPageFailure({error})))
		))
	));

	loadAttachedDebtorsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAttachedDebtorsRequest),
		withLatestFrom(
			this.fatStore.select(featureSelectors.selectAttachedDebtorsSlice),
			this.fatStore.select(featureSelectors.selectSuperCustomerId)),
		switchMap(([action, attachedCustomers, superCustomerId]) => {
				const {paging, filters} = attachedCustomers;
				return this.superCustomersService.getAttachedDebtorsBySuperCustomerId(superCustomerId, {...paging, filters}).pipe(
					map(list => featureActions.LoadAttachedDebtorsSuccess({list, correlationParams: action.correlationParams})),
					catchError(error => of(featureActions.LoadAttachedDebtorsFailure({error})))
				);
			}
		)
	));

	loadAllAttachedDebtorsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAllAttachedDebtorsRequest),
		withLatestFrom(
			this.fatStore.select(featureSelectors.selectAllAttachedDebtorsSlice),
			this.fatStore.select(featureSelectors.selectSuperCustomerId)),
		switchMap(([action, allAttachedCustomers, superCustomerId]) => {
				const {paging, filters} = allAttachedCustomers;
				return this.superCustomersService.getAttachedDebtorsBySuperCustomerId(superCustomerId, {...paging, filters}).pipe(
					map(list => featureActions.LoadAllAttachedDebtorsSuccess({list, correlationParams: action.correlationParams})),
					catchError(error => of(featureActions.LoadAllAttachedDebtorsFailure({error})))
				);
			}
		)
	));

	UpdateAttachedDebtorsSettings$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateAttachedDebtorsSettings),
		map(_ => featureActions.LoadAttachedDebtorsRequest())
	));

	loadAccountingEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAccountingRequest),
		// we need the current filter from state
		withLatestFrom(
			this.fatStore.select(featureSelectors.selectState),
			this.fatStore.select(featureSelectors.selectSuperCustomerId),
			this.fatStore.select(AppStoreSelectors.selectFactorConfiguration)),
		debounceTime(500),
		switchMap(([_, state, superCustomerId]) => {
			const { paging, filters} = this.getAccountingDocumentsFilters(state);

			return this.superCustomersService.getSuperCustomerAccounting(superCustomerId, { ...paging, filters }).pipe(
				map(list => featureActions.LoadAccountingSuccess({list: list})),
				catchError(error => of(featureActions.LoadAccountingFailure({error})))
			);
		})
	));

	// Reload data when a UpdateFilterAction is dispatched
	updateAccountingFilterEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddAccountingFilters, featureActions.RemoveAccountingFilters, featureActions.UpdateAccountingSettings),
		map(_ => featureActions.LoadAccountingRequest())
	));

	updateGroupMasterEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateGroupMaster),
		switchMap(action => this.superCustomersService.updateGroupMaster(action.superCustomerId, action.masterCustomerId).pipe(
			switchMap(_ => [
				featureActions.LoadAttachedDebtorsRequest(),
				featureActions.InitSuperCustomerPageRequest({
					id: action.superCustomerId,
					correlationParams: { correlationId: newAggregationId() }
				}),
				AppStoreActions.Execute(this.store.loadTurnoverWorldMap), // because this customer could have changed its country
			]),
			catchError(_ => of(featureActions.LoadAttachedDebtorsRequest()))
		))
	))

	exportAccountingRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportAccountingRequest),
		withLatestFrom(
			this.fatStore.select(featureSelectors.selectState),
			this.fatStore.select(AppStoreSelectors.selectFactorConfiguration)),
		switchMap(([action, state, _]) => {
			const { paging, filters} = this.getAccountingDocumentsFilters(state);
			return this.accountingService.exportAccountingList({ ...paging, filters }, true).pipe(
				map(response => {
					saveAs(response.blob, response.fileName);
					return featureActions.ExportAccountingSuccess({ correlationParams: action.correlationParams });
				}),
				catchError(error => of(
					featureActions.ExportAccountingFailure({ error }),
					AppStoreActions.ToastError('Errors.RetryableError')
				))
			);
		})
	));

	getAccountingDocumentsFilters(state: ISuperCustomerPageState) {
		const {filters: otherFilters, paging} = state.superCustomerAccountingDocuments;

		// hard filters
		let filters: IFilter[] = [this.accountingListFilterSpecService.bySuperCustomerId(state.superCustomerId)]
		filters = filters.concat(otherFilters)

		return {filters, paging};
	}
}
