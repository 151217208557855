import { DunningLevel, IFilter, IFilterOperator } from '@aston/foundation';
import { signalStoreFeature, type, withMethods } from '@ngrx/signals';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AccountingListFilterSpecService } from '../../../accounting-module/services/accounting-list-filter-spec.service';
import { AccountingDocumentTypeCode } from '../../../accounting-module/enums';
import * as RoutesDefinitions from '../../../routes-definitions'
import { OutstandingMode } from '../../../shared-module/enums';
import { SuperCustomersListFilterSpecService } from '../../../customers-module/services';
import * as AccountingStoreActions from '../../../root-store/accounting-store/actions';
import { SuperCustomersStoreActions } from '../../../root-store/super-customers-store';

export function withHomeChartsNavigation<_>() {
	return signalStoreFeature(
		{ state: type<{ filters: IFilter[] }>() },

		//// ACCOUNTING ////////////////////////////////

		withMethods(store => {
			const adSpec = inject(AccountingListFilterSpecService)
			const fatStore = inject(Store);
			const router = inject(Router)

			return {

				navigateToAccountingDocumentsByAge(args: { operator: IFilterOperator, range: Date[] }) {
					fatStore.dispatch(AccountingStoreActions.ResetAccountingFilters());
					fatStore.dispatch(AccountingStoreActions.AddAccountingFilters({filters: [
						...store.filters(),
						adSpec.onlyDocumentsWithBalance(),
						adSpec.byDueDate(args.operator, args.range),
					]}));
					router.navigateByUrl(RoutesDefinitions.getAccountingListFullPath())
				},
				
				navigateToAccountingDocumentsForTurnover(args: {
					operatorDate: IFilterOperator,
					range: Date[],
					operatorADTypes: IFilterOperator,
					types: AccountingDocumentTypeCode[],
				}) {
					fatStore.dispatch(AccountingStoreActions.ResetAccountingFilters());
					fatStore.dispatch(AccountingStoreActions.SetShowCompleted({ showCompleted: true }))
					fatStore.dispatch(AccountingStoreActions.AddAccountingFilters({filters: [
						...store.filters(),
						adSpec.byAccountingDate(args.operatorDate, args.range),
						adSpec.byAccountingDocumentType(args.operatorADTypes, args.types),
					]}));
					router.navigateByUrl(RoutesDefinitions.getAccountingListFullPath())
				},
			
				navigateToAccountingDocumentsForOutstanding(args: {
					mode: OutstandingMode,
					dunningLevel: DunningLevel,
					range: Date[],
				}) {
					const filters = [
						...store.filters(),
						adSpec.getDunningLevelFieldFilter(args.dunningLevel),
					];
		
					if (args.mode === OutstandingMode.Expired) {
						filters.push(adSpec.byDueDate(IFilterOperator.LessThan, args.range))
					}
		
					fatStore.dispatch(AccountingStoreActions.ResetAccountingFilters());
					fatStore.dispatch(AccountingStoreActions.AddAccountingFilters({ filters }));
					router.navigateByUrl(RoutesDefinitions.getAccountingListFullPath())
				}
			}
		}),

		//// SUPERCUSTOMERS ////////////////////////////////

		withMethods(store => {
			const sdSpec = inject(SuperCustomersListFilterSpecService)
			const router = inject(Router)

			return {

				navigateToSuperCustomersByCountry(args: { operator: IFilterOperator, countries: string[] }) {
					const fatStore = inject(Store);
					fatStore.dispatch(SuperCustomersStoreActions.ResetSuperCustomersFilters());
					fatStore.dispatch(SuperCustomersStoreActions.AddSuperCustomersFilters({ filters: [
						...store.filters(),
						sdSpec.byCountries(args.operator, args.countries),
					] }));
					router.navigateByUrl(RoutesDefinitions.getSuperCustomersListFullPath())
				},
			
				navigateToSuperCustomersByScoring(args: { operator: IFilterOperator, range: number[] }) {
					const fatStore = inject(Store);
					fatStore.dispatch(SuperCustomersStoreActions.ResetSuperCustomersFilters());
					fatStore.dispatch(SuperCustomersStoreActions.AddSuperCustomersFilters({ filters: [
						...store.filters(),
						sdSpec.byRisks(args.operator, args.range),
					] }));
					router.navigateByUrl(RoutesDefinitions.getSuperCustomersListFullPath())
				}
			}
		}),

	)
}
