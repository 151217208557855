import { NamedCallStateMethods, NamedCallStateSignals, NamedCallStateSlice, callStated, withCallState } from '@aston/foundation';
import { EmptyFeatureResult, SignalStoreFeature, signalStoreFeature, withMethods, withState } from '@ngrx/signals';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HomeService } from '../../services/home.service';

export type NamedChartMethods<ChartData, Prop extends string, A1, A2, CProp extends string = Capitalize<Prop>> = {
	[K in CProp as `load${K}`]: (a1: A1, a2: A2) => Promise<ChartData>;
} & NamedCallStateMethods<Prop>;

export type NamedChartState<ChartData, Collection extends string> =  {
	[K in Collection]: ChartData;
} & NamedCallStateSlice<Collection>

export function withGenericChart<ChartData, Collection extends string, A1 = void, A2 = void>({
	collection,
}: { collection: Collection }): SignalStoreFeature<
EmptyFeatureResult,
{
	state: NamedChartState<ChartData, Collection>;
	props: NamedCallStateSignals<Collection>;
	methods: NamedChartMethods<ChartData, Collection, A1, A2>;
}> {
	const Name = collection[0].toUpperCase() + collection.slice(1)
	return signalStoreFeature(
		{},
		withState({
			[collection]: null,
		} as NamedChartState<ChartData, Collection>),
		withCallState<Collection>({ collection }),
		withMethods((store, charts = inject(HomeService)) => {
			const getChart = charts[`get${Name}Kpis`] as (...args: any) => Observable<ChartData>
			
			if (!getChart) {
				console.error(`[charts] "${collection}" is not a valid slice name, there is no matching HomeService::get${Name}Kpis method.`)
			}
			
			return {
				[`load${Name}`]: (a1: A1, a2: A2) => callStated(getChart.bind(charts)(a1, a2), store, collection),
			}
		}),
	// FIXME: cannot make this typed properly :(
	) as SignalStoreFeature<
		EmptyFeatureResult,
		{
			state: NamedChartState<ChartData, Collection>;
			props: NamedCallStateSignals<Collection>;
			methods: NamedChartMethods<ChartData, Collection, A1, A2>;
		}> 
}